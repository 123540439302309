<template>
  <div
    v-shortkey="['esc']"
    class="modals-attendance"
    :class="{'modals-attendance_blur': showStraightOffModalSignUp}"
    @contextmenu.self.prevent
    @click="closeModal"
    @shortkey="closeModal"
  >
    <div
      class="modals-attendance__content"
      @click.stop
      @contextmenu.prevent
    >
      <div
        class="modals-attendance-top"
        :class="{'modals-attendance-top_prayer-wall': showStraightOffModalSignUp}"
      >
        <div
          v-if="!showStraightOffModalSignUp"
          class="modals-attendance__close"
          @click="closeModal"
        >
          <i class="icon-close-modal"></i>
        </div>
        <div class="modals-attendance__title">
          What is attendance of your organization?
        </div>
        <div
          v-if="!showStraightOffModalSignUp"
          class="modals-attendance__desc"
        >
          <span>Please input correct information as all important statistics & benchmarks will not be shown</span>
          <span>properly in your CMS.</span>
        </div>
      </div>
      <div
        class="modals-attendance-body"
        :class="{'modals-attendance-body_prayer-wall': showStraightOffModalSignUp}"
      >
        <div class="people-calc">
          <div class="people-calc__value">
            <!--{{ peopleCalcValue }}-->
            <!--<span>{{ valueSlider }}</span>-->
            <transition
              :enterActiveClass="currentPos < 2 ? 'animate-slideInUp slideInUp slow' : 'animate-slideOutUp slideInDown slow'"
              :leaveActiveClass="currentPos < 2 ? 'animate-slideOutUp slow slideOutUp' : 'animate-slideInUp slow slideOutDown'"
              mode="out-in"
              appear
            >
              <span
                v-if="valueSlider === '0 – 500'"
                key="1"
              >0 – 500</span>
              <span
                v-else-if="valueSlider === '500 - 2,000' "
                key="2"
              >500 - 2,000</span>
              <span
                v-else-if="valueSlider === '2,000 - 10,000' "
                key="3"
              >2,000 - 10,000</span>
              <span
                v-else
                key="4"
              >10,000+</span>
            </transition>
          </div>
          <div
            class="people-calc-list-pre"
            :class="{'people-calc-list-pre_prayer-wall': showStraightOffModalSignUp}"
          >
            <div class="people-calc-list">
              <div
                v-for="(item, index) in peopleCalc"
                :key="index"
                class="people-calc-item"
                :class="[{'people-calc-item_active': activeItemId === index}, item.classNameItem]"
                @click="setActiveItemId(index, item.value)"
              >
                <div
                  class="people-calc-item__img"
                  :class="{'people-calc-item__img_active': showStraightOffModalSignUp && item.value === valueSlider}"
                  v-html="item.img"
                />
                <div class="people-calc-item__value">
                  {{ item.value }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="people__slider"
            :class="{'people__slider_prayer-wall': showStraightOffModalSignUp}"
          >
            <vue-slider
              ref="sliderPeople"
              v-model="valueSlider"
              :min="0"
              :max="10000"
              :interval="6000"
              :lazy="true"
              :data="['0 – 500', '500 - 2,000', '2,000 - 10,000', '10,000+']"
              :tooltip="false"
              :startAnimation="true"
              :callback="getIndexSliderPeople()"
            >
            </vue-slider>
          </div>
        </div>
      </div>
      <div class="modals-attendance-bottom">
        <div
          class="modals-attendance__back"
          @click="$emit('update:showModalAttendance', false)"
          @click.prevent="$emit('update:showModalAttendance', false); showModalGetStarted = true"
        >
          <i class="icon-back-modal"></i>
        </div>
        <button
          class="modals-attendance__btn"
          @click="loadPricingPage()"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import vueSlider from 'vue-slider-component';
import {
  value1, value2, value3, value5,
} from '@/constants/icon';

export default {
  name: 'ModalsAttendance',
  components: {
    vueSlider,
  },
  props: ['showModalAttendance', 'showStraightOffModalSignUp'],
  data() {
    return {
      valueSlider: 0,
      activeItemId: 1,
      currentPos: 0,
      peopleCalcValue: '500 - 2,000',
      peopleCalc: [
        {
          img: value1(),
          value: '0 – 500',
          alt: 'one',
          classNameItem: 'people-calc-item_first',
        },
        {
          img: value2(),
          value: '500 - 2,000',
          alt: 'two',
          classNameItem: 'people-calc-item_second',
        },
        {
          img: value3(),
          value: '2,000 - 10,000',
          alt: 'three',
          classNameItem: 'people-calc-item_third',
        },
        {
          img: value5(),
          value: '10,000+',
          alt: 'four',
          classNameItem: 'people-calc-item_fourth',
        },
      ],
    };
  },
  computed: {
    showModalGetStarted: {
      get() {
        return this.$store.getters.showModalGetStarted;
      },
      set(data) {
        this.$store.commit('setShowModalGetStarted', data);
      },
    },
    prayerWallModule: {
      get() {
        return this.$store.getters.prayerWallModule;
      },
      set(data) {
        this.$store.commit('setPrayerWallModule', data);
      },
    },
    pricingObj: {
      get() {
        return this.$store.getters.computedPricingObj;
      },
      set(data) {
        this.$store.commit('setPricingObj', data);
      },
    },
  },
  mounted() {
    window.gtag('event', 'conversion', { send_to: 'AW-699985331/tAIgCIivlrEBELPb480C' });
  },
  methods: {
    closeModal() {
      if (!this.showStraightOffModalSignUp) {
        this.$emit('update:showModalAttendance', false);
      }
    },
    setActiveItemId(itemIndex, item) {
      console.log('itemIndex, item', itemIndex, item);
      this.activeItemId = itemIndex;
      this.peopleCalcValue = item;
      this.valueSlider = item;
    },
    getIndexSliderPeople() {
      console.log(12);
      this.$nextTick(() => {
        this.pricingObj.attendance = this.$refs.sliderPeople.getIndex();
        // if (this.pricingObj.attendance === 0) {
        //   this.pricingObj.amount = 25
        // } else if (this.pricingObj.attendance === 1) {
        //   this.pricingObj.amount = 50
        // } else if (this.pricingObj.attendance === 2) {
        //   this.pricingObj.amount = 150
        // } else if (this.pricingObj.attendance === 3) {
        //   this.pricingObj.amount = 250
        // }
        console.warn(this.pricingObj);
      });
    },
    loadPricingPage() {
      // if (this.showStraightOffModalSignUp) {
      //   this.pricingObj.services = {
      //     prayerWall: [],
      //     additionals: [],
      //   };
      //   this.prayerWallModule[0].checked = true;
      //   document.body.style.overflowY = 'visible';
      //   window.fbq('trackCustom', 'Sign Up Form Step 3 - Pricing');
      //   this.$router.push({ name: 'pricingStep1' });
      // } else {
      //   window.fbq('trackCustom', 'Sign Up Form Step 2 - Size');
      //   this.$router.push({ name: 'pricingPage' });
      // }
      window.fbq('trackCustom', 'Sign Up Form Step 2 - Size');
      this.$router.push({ name: 'pricingPage' });

      this.$emit('update:showModalAttendance', false);
      console.log('showStraightOffModalSignUp', this.showStraightOffModalSignUp);
      // window.fbq('trackCustom', 'Sign Up Form Step 2 - Size');
      // this.$router.push({ name: 'pricingPage' });
    },
    // imageLink: function (img) {
    //   return require('../../assets/images/' + img + '.png')
    // }
  },
};
</script>

<style lang="scss" scoped>
  @import '../../assets/sass/utils/variables';

  .modals-attendance {
    position: fixed;
    top: 0;
    left: 0;
    /*width: 100vw;*/
    /*height: 100vh;*/
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity .5s ease-in;
    background: rgba(0, 0, 0, .8);

    .people-calc {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-bottom: 45px;

      &__value {
        max-width: 421px;
        width: 100%;
        background: transparent;
        border: 2px solid $color-white;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $font-global;
        font-size: 45px;
        color: $color-white;
        margin-bottom: 38px;
        height: 74px;
        user-select: none;
        margin-right: 22px;
        overflow: hidden;
      }

      &-list {
        display: flex;
        align-items: center;
        position: relative;
        max-width: 473px;
        width: 100%;
        justify-content: space-between;
        margin-right: 4px;
        z-index: 5;
        user-select: none;

        &-pre {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: center;

          &:after {
            position: absolute;
            content: '';
            bottom: -62px;
            left: 0;
            width: 100%;
            background: $color-scooter;
            height: 100px;
          }

          &_prayer-wall {
            &:after {
              background: $color-attendance-substrate;
            }
          }
        }

        /*<!--&:after {-->*/
        /*<!--content: '';-->*/
        /*<!--position: absolute;-->*/
        /*<!--height: 1px;-->*/
        /*<!--width: 420px;-->*/
        /*<!--background: $color-white;-->*/
        /*<!--left: 49%;-->*/
        /*<!--top: 66%;-->*/
        /*<!--transform: translate(-50%, -50%);-->*/
        /*<!--}-->*/
      }

      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        cursor: pointer;

        &_first {
          .people-calc-item__value {
            top: 2px;
            left: 5px;
          }
        }

        &_second {
          left: 13px;

          .people-calc-item__value {
            top: 2px;
            left: -1px;
          }
        }

        &_third {
          left: 10px;

          .people-calc-item__img {
            top: -2px;

            &:after {
              bottom: -26px;
            }
          }
        }

        &_fourth {
          left: 7px;

          .people-calc-item__img {
            top: -6px;

            &:after {
              bottom: -30px;
            }
          }

          .people-calc-item__value {
            left: 2px;
          }
        }

        &__img {
          margin-bottom: 36px;
          max-height: 60px;
          position: relative;
          color: $color-white;
          transition: color 0.2s;

          &:after {
            position: absolute;
            content: '';
            left: 50%;
            bottom: -28px;
            transform: translateX(-50%);
            border-radius: 50%;
            width: 5px;
            height: 5px;
            background: $color-white;
          }

          &_active {
            color: $color-orange-fizz;
          }
        }

        &__value {
          font-family: $font-global;
          font-size: 14px;
          color: $color-white;
          position: relative;
          top: 1px;
        }

        &_active {
          .people-calc-item__img {
            /*&:after {
              width: 20px;
              height: 20px;
              border: 1px solid $color-white;
              background: $color-golden-fizz;
              bottom: -25px;
              z-index: 5;
            }*/
          }
        }
      }

      /*.people-calc-item_active.people-calc-item_third {
        .people-calc-item__img {
          &:after {
            bottom: -27px !important;
          }
        }
      }*/

      /*.people-calc-item_active.people-calc-item_fourth {
        .people-calc-item__img {
          &:after {
            bottom: -29px !important;
          }
        }
      }*/
    }

    &-top {
      background: $color-picton-blue;
      padding: 65px 15px 33px 15px;
      border-radius: 10px 10px 0 0;
      box-sizing: border-box;

      &_prayer-wall {
        background: $color-attendance-surface;
      }
    }

    &-body {
      background: $color-picton-blue;

      &_prayer-wall {
        background: $color-attendance-surface;
      }
    }

    &__content {
      max-width: 946px;
      width: 100%;
      border-radius: 10px;
      position: relative;
      user-select: none;
    }

    &__close {
      position: absolute;
      top: 23px;
      right: 16px;
      font-size: 16px;
      color: $color-paradiso;
      transition: transform .15s ease-in;
      cursor: pointer;

      &:hover {
        transform: rotate(90deg);
      }
    }

    &__title {
      font-family: $font-global-bold;
      line-height: 48px;
      font-size: 37px;
      color: $color-white;
      text-align: center;
      margin-bottom: 12px;
    }

    &-bottom {
      min-height: 120px;
      display: flex;
      align-items: center;
      position: relative;
      padding-top: 5px;
      border-radius: 0 0 10px 10px;
      background: $color-white;
    }

    &__back {
      position: absolute;
      top: 53%;
      transform: translateY(-50%);
      font-size: 20px;
      left: 30px;
      cursor: pointer;
      color: $color-jumbo;
      transition: opacity .15s ease-in;

      &:hover {
        opacity: .6;
      }
    }

    &__btn {
      margin: auto;
      background: $color-moody-blue;
      height: 48px;
      border: none;
      font-family: $font-global-medium;
      font-size: 21px;
      color: $color-white;
      padding: 0 6px;
      width: 189px;
      border-radius: 5px;
      text-decoration: none;
      padding-bottom: 2px;
      transition: opacity .2s ease-in, background .2s ease-in;
      user-select: none;

      &:hover {
        opacity: .8;
      }

      &:active {
        background: $color-martinique;
        opacity: 1;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        background: rgba(255, 255, 255, .5);
        opacity: 0;
        border-radius: 100%;
        transform: scale(1, 1) translate(-50%);
        transform-origin: 50% 50%;
      }

      @keyframes ripple {
        0% {
          transform: scale(0, 0);
          opacity: 1;
        }
        20% {
          transform: scale(25, 25);
          opacity: 1;
        }
        100% {
          opacity: 0;
          transform: scale(40, 40);
        }
      }

      &:active {
        color: $color-white;
      }

      &:focus:not(:active)::after {
        animation: ripple 1.5s ease-out;
      }
    }

    &__desc {
      line-height: 34px;
      font-size: 24px;
      text-align: center;
      font-family: $font-global;
      color: $color-white;

      span {
        display: block;
      }
    }

    &_blur {
      backdrop-filter: blur(8px);
      background: rgba(0, 0, 0, .9);
    }
  }
  @keyframes scaleUp {
    0% {
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes scaleDown {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(1000px);
      opacity: 0;
    }
  }

  .people__slider {
    position: relative;
    left: -6px;
    top: -47px;
    z-index: 9;
    cursor: pointer;
    user-select: none;
    width: 430px;

    .vue-slider-component {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 25px;
        left: -20px;
        height: 30px;
        width: 475px;
      }

      //&:after {
      //  content: '';
      //  position: absolute;
      //  bottom: 15px;
      //  left: -10px;
      //  height: 80px;
      //  width: 480px;
      //}

      .vue-slider-process {
        background-color: $color-white;
        height: 1px;
      }

      .vue-slider {
        background-color: $color-white;
        height: 1px !important;
      }

      .vue-slider-dot {

        &-handle {
          width: 20px;
          height: 20px;
          border: 1px solid $color-white;
          background: $color-golden-fizz !important;
          z-index: 5;
          border-radius: 50%;
          position: relative;
          top: -4px;
        }
      }
    }
  }

  @media (max-width: 650px) {
    .modals-attendance {
      .people-calc__value {
        width: 230px;
        margin: 0 auto 45px;
        font-size: 28px;
      }

      .people-calc-list-pre {
        padding: 0 20px;
      }

      .people-calc-list {
        max-width: 361px;
        align-items: flex-start;
        margin: 0;
      }

      .people-calc-item {
        width: 70px;
        left: 0;

      }

      .people-calc-item__img {
        top: inherit !important;
      }

      .people-calc-item__value {
        font-size: 10px;
        text-align: center;
      }

      .people__slider {
        width: 312px;
        left: 0;
      }
      .people-calc-item__img {
        margin-bottom: 36px;
        max-height: 61px;
        position: relative;
        display: flex;
        align-items: center;
        height: 55px;

        &:after {
          bottom: -24px !important;
        }

        img {
          max-width: 65px;
          max-height: 43px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .modals-attendance {

      &__close {
        top: 10px;
        right: 10px;
      }

      &__back {
        font-size: 14px;
      }

      &-bottom {
        min-height: 80px;
      }

      &-top {
        padding-top: 40px;
      }

      .people {
        &__slider {
          .vue-slider-component {
            .vue-slider-dot-handle {
              width: 16px;
              height: 16px;
              top: -3px;
            }
          }
        }
      }

      .people-calc {
        padding-bottom: 15px;

        &__value {
          height: 45px;
          font-size: 20px;
          margin-bottom: 30px;
        }
      }

      &__btn{
        width: 140px;
        height: 32px;
        font-size: 14px;
      }

      &__title{
        line-height: 24px;
        font-size: 19px;
      }

      &__desc{
        line-height: 25px;
        font-size: 16px;
      }
    }
  }

  @media (max-width: 400px) {
    .modals-attendance {
      .people-calc-list {
        max-width: 260px;
      }

      .people__slider {
        width: 212px;
      }

      .people-calc-item__value {
        font-size: 9px;
      }

      .people-calc-item__img {
        margin-bottom: 36px;
        max-height: 61px;
        position: relative;
        display: flex;
        align-items: center;
        height: 55px;

        &:after {
          bottom: -24px !important;
        }

        img {
          max-width: 65px;
          max-height: 43px;
        }
      }
    }
  }

</style>

<style lang="scss">
@import '../../assets/sass/utils/variables';

.people__slider {

  .vue-slider-component {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 25px;
      left: -20px;
      height: 30px;
      width: 475px;
    }

    //&:after {
    //  content: '';
    //  position: absolute;
    //  bottom: 15px;
    //  left: -10px;
    //  height: 80px;
    //  width: 480px;
    //}

    .vue-slider-process {
      background-color: $color-white;
      height: 1px;
    }

    .vue-slider {
      background-color: $color-white;
      height: 1px !important;
    }

    .vue-slider-dot {

      &-handle {
        width: 20px;
        height: 20px;
        border: 1px solid $color-white;
        background: $color-golden-fizz !important;
        z-index: 5;
        border-radius: 50%;
        position: relative;
        top: -4px;
      }
    }
  }

  &_prayer-wall {
    .vue-slider-component {
      .vue-slider-dot {
        &-handle {
          background: $color-orange-fizz !important;
        }
      }
    }
  }
}
</style>
